<script>
  import Select from "svelte-select";

  import DrinkList from "./DrinkList.svelte";

  const items = [
    { value: "absinthe", label: "Absinthe" },
    { value: "angosturaBitters", label: "Angostura Bitters" },
    { value: "apricotBrandy", label: "Apricot Brandy" },
    { value: "blackPepper", label: "Black Pepper" },
    { value: "bourbon", label: "Bourbon" },
    { value: "brandy", label: "Brandy" },
    { value: "cachaca", label: "Cachaça" },
    { value: "calvados", label: "Calvados" },
    { value: "campari", label: "Campari" },
    { value: "celerySalt", label: "Celery Salt" },
    { value: "champagne", label: "Champagne" },
    { value: "coffeeLiqueur", label: "Coffee Liqueur" },
    { value: "cognac", label: "Cognac" },
    { value: "cranberryJuice", label: "Cranberry Juice" },
    { value: "cream", label: "Cream" },
    { value: "cremeDeCacao", label: "Crème de Cacao" },
    { value: "cremeDeMenthe", label: "Crème de Menthe" },
    { value: "cremeDeViolette", label: "Crème de Violette" },
    { value: "drambuie", label: "Drambuie" },
    { value: "dryVermouth", label: "Dry Vermouth" },
    { value: "eggWhite", label: "Egg White" },
    { value: "eggYolk", label: "Egg Yolk" },
    { value: "gin", label: "Gin" },
    { value: "gommeSyrup", label: "Gomme Syrup" },
    { value: "grenadine", label: "Grenadine" },
    { value: "lemonJuice", label: "Lemon Juice" },
    { value: "lime", label: "Lime" },
    { value: "limeJuice", label: "Lime Juice" },
    { value: "maraschino", label: "Maraschino Liqueur" },
    { value: "mintLeaves", label: "Mint Leaves" },
    { value: "orangeBitters", label: "Orange Bitters" },
    { value: "orangeFlowerWater", label: "Orange Flower Water" },
    { value: "orangeJuice", label: "Orange Juice" },
    { value: "peachBitters", label: "Peach Bitters" },
    { value: "peachPuree", label: "Peach Purée" },
    { value: "peychaudsBitters", label: "Peychaud's Bitters" },
    { value: "pineappleJuice", label: "Pineapple Juice" },
    { value: "port", label: "Port" },
    { value: "prosecco", label: "Prosecco" },
    { value: "raspberrySyrup", label: "Raspberry Syrup" },
    { value: "rum", label: "Rum" },
    { value: "rye", label: "Rye" },
    { value: "simpleSyrup", label: "Simple Syrup" },
    { value: "sodaWater", label: "Soda Water" },
    { value: "sugar", label: "Sugar" },
    { value: "sugarCube", label: "Sugar Cube" },
    { value: "sweetVermouth", label: "Sweet Vermouth" },
    { value: "tabascoSauce", label: "Tabasco Sauce" },
    { value: "tomatoJuice", label: "Tomato Juice" },
    { value: "tripleSec", label: "Triple Sec" },
    { value: "vanillaExtract", label: "Vanilla Extract" },
    { value: "vodka", label: "Vodka" },
    { value: "water", label: "Water" },
    { value: "whiteRum", label: "White Rum" },
    { value: "worcestershireSauce", label: "Worcestershire Sauce" },
    { value: "cola", label: "Cola" },
    { value: "scotch", label: "Scotch" },
  ];

  let selectedValue = undefined;
  let drinks = [];

  function handleSelect(event) {
    if (event.detail == null) {
      drinks = [];
      return;
    }
    fetch("/v1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ingredients: event.detail.map((drink) => drink.value),
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        drinks = json.drinks;
      });
  }

  function handleDrinkClick(event) {
    console.log(event);
  }
</script>

<main>
  <header>
    <h1>
      Drink
      <span class="noswash">Right</span>
      Now
    </h1>
    <p>
      Select sprits and mixers to learn which cocktails can be made with them.
    </p>
  </header>
  <div class="themed">
    <Select
      {items}
      on:select={handleSelect}
      bind:selectedValue
      isMulti="true"
      placeholder="Ingredients..."
      noOptionsMessage="&#x1F937;"
    />
  </div>
  {#if drinks.length > 0}
    <DrinkList {drinks} />
  {/if}
</main>

<style>
  .themed {
    --multiItemPadding: 3px 10px;
    --multiItemBorderRadius: 0.25em;
    --multiClearRadius: 0.25em;
    --multiClearTop: 4px;
    --multiLabelMargin: 0px 6px 0 0;
    --borderFocusColor: #e36209;
    --multiItemActiveBG: #e36209;
    --itemHoverBG: rgba(227, 98, 9, 0.1);
    --multiItemHeight: 24px;
    --multiClearHoverFill: #e36209;
    --inputFontSize: 16px;

    margin-bottom: 2em;
  }

  header {
    color: #888b8d;
    text-align: center;
    margin-bottom: 2em;
  }

  header h1 {
    font-size: 40px;
    font-family: "fairwater", sans-serif;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -webkit-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -ms-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -moz-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    line-height: 54px;
    margin-bottom: 0;
  }

  h1 span.noswash {
    font-feature-settings: "liga" 1, "calt" 1;
    -webkit-font-feature-settings: "liga" 1, "calt" 1;
    -ms-font-feature-settings: "liga" 1, "calt" 1;
    -moz-font-feature-settings: "liga" 1, "calt" 1;
  }

  header p {
    margin-top: 0.5em;
  }

  footer {
    color: #888b8d;
    font-size: 13px;
    position: absolute;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 2rem;
  }

  main {
    min-height: 100vh;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 2rem;
  }
</style>
