<script>
  import Drink from "./Drink.svelte";

  export let drinks = [];

  function handleClick(ingredient) {
    console.log(ingredient);
  }
</script>

<style>
  ul {
    margin: 0px auto;
    padding: 0;
    padding-bottom: 5vh;

    list-style: none;

    border: 1px solid #d1d5da;
    border-radius: 3px;

    background-color: #fff;
  }

  li {
    padding: 2vh 5vw;
  }

  li:last-child {
    border: none;
  }
</style>

<ul>
  {#each drinks as drink}
    <li>
      <Drink {...drink} on:click={handleClick} />
    </li>
  {/each}
</ul>
