<script>
  import { createEventDispatcher } from "svelte";

  export let display_name = undefined;
  export let missing = [];
  export let recipe = {};
  export let ingredients = [];

  const dispatch = createEventDispatcher();
  const swash_blacklist = ["mary", "angel", "negroni", "old", "sazerac"];

  function createHandler(ingredient) {
    return () => {
      dispatch("click", ingredient);
    };
  }

  function joinMissing(missing) {
    switch (missing.length) {
      case 1:
        return missing.map(i => ingredients[i].display_name);
      case 2:
        return missing.map(i => ingredients[i].display_name).join(" and ");
      default:
        return (
          missing
            .slice(0, -1)
            .map(i => ingredients[i].display_name)
            .join(", ") +
          ", and " +
          ingredients[missing.slice(-1)].display_name
        );
    }
  }
</script>

<style>
  ul.leaders {
    width: 100%;
    max-width: 24em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
  }
  ul.leaders li:before {
    float: left;
    width: 0;
    white-space: nowrap;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
  }

  ul.leaders span:first-child {
    padding-right: 0.33em;
    background: white;
  }

  ul.leaders span + span {
    float: right;
    padding-left: 0.33em;
    background: white;
  }
  ul.leaders span + span:before {
    content: " ";
    padding-right: 0.15em;
  }

  .center {
    width: 80%;
    margin: auto;
  }

  .construction {
    width: 100%;
    max-width: 24em;
    margin: auto;
  }

  .center h1 {
    text-align: center;
  }

  .missing {
    display: flex;
    margin-top: 2vh;
  }

  .missing span:first-child {
    padding-right: 0.5em;
  }

  .missing span + span {
    font-style: italic;
  }

  div {
    font-family: "Calisto MT", "Bookman Old Style", Bookman, "Goudy Old Style",
      Garamond, "Hoefler Text", "Bitstream Charter", Georgia, serif;
  }

  h1 {
    font-size: 40px;
    font-family: "fairwater", sans-serif;
    font-weight: normal;

    line-height: 64px;
    text-rendering: optimizeLegibility;
  }

  h1 span.swash {
    font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -webkit-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -ms-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
    -moz-font-feature-settings: "liga" 1, "calt" 1, "swsh" 1;
  }

  h1 span.noswash {
    font-feature-settings: "liga" 1, "calt" 1;
    -webkit-font-feature-settings: "liga" 1, "calt" 1;
    -ms-font-feature-settings: "liga" 1, "calt" 1;
    -moz-font-feature-settings: "liga" 1, "calt" 1;
  }
</style>

<div class="center">
  <h1>
    {#each display_name.split(' ') as word, index}
      {#if index != 0}
        <span />
      {/if}
      {#if swash_blacklist.includes(word.toLowerCase())}
        <span class="noswash">{word}</span>
      {:else}
        <span class="swash">{word}</span>
      {/if}
      <!-- &nbsp; -->
    {/each}
  </h1>
  <div class="construction">
    <ul class="leaders">
      {#each Object.keys(recipe) as required}
        <li>
          <span>{ingredients[required].display_name}</span>
          <span>{recipe[required]}</span>
        </li>
      {/each}
    </ul>

    {#if missing.length > 0}
      <div class="missing">
        <span>Missing:</span>
        <span>{joinMissing(missing)}</span>
      </div>
    {/if}
  </div>

  <!-- {#if missing.length > 0}
    <h2>Missing</h2>
    <ul>
      {#each missing as ingredient}
        <li on:click={createHandler(ingredient)}>{ingredient.display_name}</li>
      {/each}
    </ul>
  {/if} -->
</div>
